/**
 * @file   开发时间
 * @create macaiqi 2023/03/30
 * @update
 */
import React from 'react';

export default function OpenTime({ routes }) {
  return (
    <div className='opentime'>
      <div className="bigtitle">
        <h3>
          <div className="cn">开放时间</div>
          <div className="en">Opening Hours</div>
        </h3>
      </div>
      <div className="tipsnew margintop">
        <p>为了给您带来更优质的参观体验，我们全力优化开放服务，于2024年7月20日至2024年8月31日期间延时开放，开放时间为</p>
      </div>
      <div className="timenew">
        <div className="i">
          <p className="t">9:00</p>
          <p className="d">入馆时间</p>
          <i>-</i>
        </div>
        <div className="i">
          <p className="t">17:30</p>
          <p className="d">停止入馆</p>
          <i>-</i>
        </div>
        <div className="i">
          <p className="t">18:00</p>
          <p className="d">闭馆时间</p>
        </div>
      </div>
      <div className="tipsnew">
        <p>（17:30停止入馆，周一正常闭馆）</p>
      </div>
      <div className="linenew" />
      <div className="tipsnew">
        <div className="sectitle">【个人参观】</div>
        <p>宜宾市博物院现已实行免预约参观，观众可直接到馆，接受安全检查后方可入馆参观。</p>
      </div>
      <div className="tipsnew">
        <div className="sectitle">【团队预约】</div>
        <p>团队参观请提前预约，预约咨询电话：0831-3663222</p>
      </div>
    </div>
  )
}
